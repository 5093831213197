<template>
    <div class="px-4 text-14 pb-5">
        <form class="p-fluid pt-2" @submit.prevent="">
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Sumber Dana *
                </label>
                <Dropdown
                    v-model="form.selected_sof"
                    :options="sofs"
                    optionLabel="name"
                    placeholder="Pilih Sumber Dana"
                    :disabled="form.is_submit"
                />
            </div> 
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Tipe *
                </label>
                <Dropdown
                    v-model="form.selected_type"
                    :options="types"
                    optionLabel="value"
                    placeholder="Pilih Tipe"
                    :disabled="form.is_submit"
                />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Servis *
                </label>
                <Dropdown
                    v-model="form.selected_service"
                    :options="services"
                    optionLabel="name"
                    placeholder="Pilih Servis"
                    :disabled="form.is_submit"
                />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Kode Bank *
                </label>
                <Dropdown
                    v-model="form.selected_bank"
                    :options="banks"
                    optionLabel="bank_name"
                    :filter="true"
                    placeholder="Kode Bank"
                    :showClear="true"
                >
                    <template #value="slotProps">
                        <div class="country-item country-item-value" v-if="slotProps.value">
                            <div>
                                {{ slotProps.value.bank_code }} - {{ slotProps.value.bank_name }}
                            </div>
                        </div>
                        <span v-else>
                            {{slotProps.placeholder}}
                        </span>
                    </template>
                    <template #option="slotProps">
                        <div class="country-item">
                            {{ slotProps.option.bank_code }} - {{ slotProps.option.bank_name }}
                        </div>
                    </template>
                </Dropdown>
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    No. Rekening *
                </label>
                <InputText
                    type="number"
                    placeholder="Nomor Rekening"
                    v-model="form.account_number"
                    :disabled="form.is_submit"
                />
            </div>
            <div class="field mb-4" v-if="form.selected_type.value && !form.selected_type.value.includes('TRANSFER')">
                <label class="text-black text-14 roboto-medium">
                    Produk *
                </label>
                <Dropdown
                    v-model="form.selected_product"
                    :options="products"
                    optionLabel="name"
                    placeholder="Pilih Produk"
                    :disabled="form.is_submit"
                />
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props: ['data', 'services', 'products', 'banks', 'sofs'],
    components: {},
    data() {
        return {
            form: {
                id: null,
                account_number: '',
                selected_service: '',
                service_name: '',
                selected_product: '',
                product_id: null,
                selected_type: '',
                type: '',
                selected_bank: null,
                bank_code: '',
                selected_sof: '',
                sof_id: '',
                is_submit: false,
            },
            types: [
                {value: 'PURCHASE_CREDIT'},
                {value: 'PURCHASE_DATA'},
                {value: 'PAYMENT'},
                {value: 'TRANSFER_INBOUND'},
                {value: 'TRANSFER_MYNT'},
            ],
        }
    },
    watch: {
        'form': {
            handler: function() {
                this.form.service_name = this.form.selected_service ? this.form.selected_service['name'] : ''
                this.form.type = this.form.selected_type ? this.form.selected_type['value'] : ''
                this.form.bank_code = this.form.selected_bank ? this.form.selected_bank['bank_code'] : ''
                this.form.product_id = !this.form.type.includes('TRANSFER')
                    ? this.form.selected_product ? this.form.selected_product['id'] : null
                    : null
                this.form.product_name = !this.form.type.includes('TRANSFER')
                    ? this.form.selected_product ? this.form.selected_product['name'] : ''
                    : ''
                this.form.sof_id = this.form.selected_sof ? this.form.selected_sof['id'] : ''
                this.form.sof_name = this.form.selected_sof ? this.form.selected_sof['name'] : ''
                this.$emit('updated', this.form)
            },
            deep: true,
        },
    },
    mounted() {
        if (Object.keys(this.data).length === 0) return

        this.form = {...this.form, ...this.data}
        this.form.selected_service = this.services.find(obj => obj.name == this.form.service_name) ?? '';
        this.form.selected_product = this.form.product_id ? this.products.find(obj => obj.id == this.form.product_id) : '';
        this.form.selected_type = this.types.find(obj => obj.value == this.form.type) ?? '';
        this.form.selected_bank = this.banks.find(obj => obj.bank_code == this.form.bank_code) ?? '';
        this.form.selected_sof = this.sofs.find(obj => obj.id == this.form.sof_id) ?? '';
    },
    methods: {},
}
</script>

<style lang="scss" scoped></style>
